import React from "react";
import seo from "../../../assets/images/seo.png";

const SEOScores = () => {
    return (
        <section id="seo_scores">
            <div className="site_container">
                <div className="inner">
                    <div className="text_blk">
                        <h2>
                            We are best in <span>SEO scores</span>
                        </h2>
                        <p>
                            We have optimized the back end, so that the base model gives 70+
                            seo score every time you generate articles.
                        </p>
                    </div>
                    <div className="img_blk">
                        <img src={seo} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SEOScores;
