import { toast } from "react-toastify";
import * as api from "../../api/api";
import { auth } from "../type";

export const setAuthenticationToken = (payload) => ({
	type: auth.SET_AUTH_TOKEN,
	payload,
});

export const setUserDetails = (payload) => ({
	type: auth.SET_USER_DETAILS,
	payload,
});

export const setAuthError = (payload) => ({
	type: auth.SET_AUTH_ERROR,
	payload,
});

export const setAuthLoader = (payload) => ({
	type: auth.SET_AUTH_LOADER,
	payload,
});

export const setRedirectSuccess = (payload) => ({
	type: auth.SET_REDIRECT_SUCCESS,
	payload,
});

export const logout = () => (dispatch) => {
	localStorage.clear();
	window?.location?.reload();
};

export const loginUser = (data) => (dispatch) => {
	dispatch(setAuthLoader(true));
	api
		.signinUser(data)
		.then((response) => {
			if (response?.data?.user?.isEmailVerified === true) {
				localStorage.setItem("Token", response?.data?.accessToken);
				dispatch(setAuthenticationToken(response?.data?.accessToken));
				localStorage.setItem("User", JSON.stringify(response?.data?.user));
				dispatch(setUserDetails(JSON.stringify(response?.data?.user)));
				dispatch(setAuthLoader(false));
				toast.success("Congratulations you are logged in.");
			} else {
				localStorage.setItem("User", JSON.stringify(response?.data?.user));
				dispatch(setUserDetails(JSON.stringify(response?.data?.user)));
				dispatch(setAuthLoader(false));
				toast.info("Kindly verify your email.");
			}
		})
		.catch((err) => {
			dispatch(setAuthLoader(false));
			console.log({ err });
		});
};

export const getUserDetails = (userId, data) => (dispatch, getState) => {
	const state = getState();
	const token = state?.Authentication?.token;
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	api
		.getUserDetail(userId, config, data)
		.then((response) => {
			localStorage.setItem("User", JSON.stringify(response?.data));
			dispatch(setUserDetails(JSON.stringify(response?.data)));
		})
		.catch((err) => {
			console.log({ err });
		});
};

export const loginUserWithGoogle = (data) => (dispatch) => {
	dispatch(setAuthLoader(true));
	api
		.loginUserWithGoogle(data)
		.then((response) => {
			localStorage.setItem("Token", response?.data?.accessToken);
			dispatch(setAuthenticationToken(response?.data?.accessToken));
			localStorage.setItem("User", JSON.stringify(response?.data?.user));
			dispatch(setUserDetails(JSON.stringify(response?.data?.user)));
			setAuthLoader(false);
			dispatch(setAuthLoader(false));
			toast.success("Congratulations you are logged in.");
		})
		.catch((err) => {
			dispatch(setAuthLoader(false));
			console.log({ err });
		});
};

export const registerUser = (data) => (dispatch) => {
	dispatch(setAuthLoader(true));
	api
		.signupUser(data)
		.then((response) => {
			localStorage.setItem("Token", response?.data?.accessToken);
			dispatch(setAuthenticationToken(response?.data?.accessToken));
			dispatch(setUserDetails(JSON.stringify(response?.data?.user)));
			localStorage.setItem("User", JSON.stringify(response?.data?.user));
			dispatch(setAuthLoader(false));
			toast.success("Congratulations you are registered.");
		})
		.catch((err) => {
			dispatch(setAuthLoader(false));
			console.log({ err });
		});
};

export const registerUserWithRefferal = (referralCode, data) => (dispatch) => {
	dispatch(setAuthLoader(true));
	api
		.signupUserWithRefferal(referralCode, data)
		.then((response) => {
			localStorage.setItem("Token", response?.data?.accessToken);
			dispatch(setAuthenticationToken(response?.data?.accessToken));
			dispatch(setUserDetails(JSON.stringify(response?.data?.user)));
			localStorage.setItem("User", JSON.stringify(response?.data?.user));
			dispatch(setAuthLoader(false));
			toast.success("Congratulations you are registered.");
		})
		.catch((err) => {
			dispatch(setAuthLoader(false));
			console.log({ err });
		});
};

export const forgotPassword = (data) => (dispatch, getState) => {
	dispatch(setAuthLoader(true));
	dispatch(setRedirectSuccess(false));
	api
		.forgotPassword(data)
		.then((res) => {
			dispatch(setRedirectSuccess(true));
			dispatch(setAuthLoader(false));
			toast.success("Verification link sent to your email.");
		})
		.catch((err) => {
			dispatch(setRedirectSuccess(false));
			dispatch(setAuthLoader(false));
		});
};

export const resetPassword = (data) => (dispatch, getState) => {
	dispatch(setAuthLoader(true));
	dispatch(setRedirectSuccess(false));
	api
		.resetPassword(data)
		.then((res) => {
			dispatch(setRedirectSuccess(true));
			dispatch(setAuthLoader(false));
			toast.success("Your password has been changed.");
		})
		.catch((err) => {
			dispatch(setRedirectSuccess(false));
			dispatch(setAuthLoader(false));
		});
};

export const updatePassword = (data) => (dispatch, getState) => {
	dispatch(setAuthLoader(true));
	dispatch(setRedirectSuccess(false));
	api.updateUserPassword(data);
};

export const verifyUserEmail = (token, data) => (dispatch) => {
	dispatch(setAuthLoader(true));
	api
		.verifyEmail(token, data)
		.then((response) => {
			dispatch(setRedirectSuccess(false));
			localStorage.setItem("Token", response?.data?.accessToken);
			dispatch(setAuthenticationToken(response?.data?.accessToken));
			localStorage.setItem("User", JSON.stringify(response?.data?.user));
			dispatch(setUserDetails(JSON.stringify(response?.data?.user)));
			dispatch(setAuthLoader(false));
			toast.success("Congratulations!!!");
		})
		.catch((err) => {
			dispatch(setRedirectSuccess(true));
			dispatch(setAuthLoader(false));
			console.log({ err });
		});
};
