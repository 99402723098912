import React from "react";
import img1 from "../../../assets/images/img1.png";
import img2 from "../../../assets/images/img2.png";
import img4 from "../../../assets/images/img4.png";
import img5 from "../../../assets/images/img5.png";
import img6 from "../../../assets/images/img6.png";

import "./ZapierIntegrationGuide.scss";

const ZapierIntegrationGuide = () => {
  return (
    <>
      <section id="zapier_guide">
        <h2>Zapier Integration Guide</h2>

        <div className="inner">
          <h3>Prerequisites (Important)</h3>
          <ol>
            <li>
              Create Zapier account with same email that is used on Trolly.
            </li>
            <li>
              You have to create at least one sample campaign in Trolly’s bulk
              generation section, so that zapier could access sample data as
              example to start processing your zaps.
            </li>
          </ol>
          <h3 className="mt-5">Step-wise Guide</h3>
          <div className="steps">
            <h3 className="">Step 1</h3>
            <p>Login into your zapier account</p>
            <div className="img_blk">
              <img src={img1} alt="login" />
            </div>
          </div>
          <div className="steps">
            <h3 className="">Step 2</h3>
            <p>Create a new zap, by connecting Trolly.ai and wordpress apps.</p>
            <div className="img_blk">
              <img src={img2} alt="login" />
            </div>
          </div>
          <div className="steps">
            <h3 className="">Step 3</h3>
            <p>
              Choose from two options either “New Article” or “Publish Button”
            </p>
            <div className="inner_text">
              <p>
                <b>New Article:</b> Option for bulk generation campaigns.
              </p>
              <p>
                <b>Publish Button:</b> Option that allows user to publish
                articles by selecting bulk articles manually from the generated
                articles in “Bulk generated” campaigns.
              </p>
            </div>
          </div>
          <div className="steps">
            <h3 className="">Step 4</h3>
            <p>Connect your Trolly.ai account, to start the automation.</p>
            <div className="img_blk">
              <img src={img4} alt="login" />
            </div>
          </div>
          <div className="steps">
            <h3 className="">Step 5</h3>
            <p>Select your trigger & sample data with it.</p>
            <div className="img_blk">
              <img src={img5} alt="login" />
            </div>
          </div>
          <div className="steps">
            <h3 className="">Step 6</h3>
            <p>
              Attach your WordPress account and start publishing the articles,
              after zap is published, you can automatically push bulk articles
              from Trolly’s dashboard rather than using Zapier time to time.
            </p>
            <div className="img_blk">
              <img src={img6} alt="login" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZapierIntegrationGuide;
