import React, { useState } from "react";
// import send from "../../../assets/images/send.svg";

import "./selectedCampaign.scss";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../../config/routes";
import moment from "moment";
import { toast } from "react-toastify";
import { articlePushToZapier } from "../../../../redux/actions/bulkGeneration";
import { DotLoader } from "react-spinners";
const SelectedCampaign = () => {

    const campaignById = useSelector((state) => state?.BulkGeneration?.campaginById);

    const [articleRedirect, setArticleRedirect] = useState(false);

    const [selectedArticle, setSelectedArtile] = useState('');

    const dispatch = useDispatch();

    const campaignLoader = useSelector((state) => state?.BulkGeneration?.campaignLoader);

    const handleViewArticle = (value) => {
        setArticleRedirect(true);
        localStorage.setItem('Value', JSON.stringify(value));
    }

    const handlePushToZapier = (e) => {
        e.preventDefault();

        if (!selectedArticle) {
            toast.info('Please select an article to push to zapier');
        }
        else {
            dispatch(articlePushToZapier(selectedArticle, campaignById?._id));
            setSelectedArtile('');
        }
    }

    if (Object?.keys(campaignById).length === 0) {
        return <Redirect to={routes.panel.bulkGeneration} />
    }

    if (articleRedirect) {
        return <Redirect to={routes.panel.previewCampaignArticle} />
    }

    return (
        <>
            <section id="SelectedCampaign">
                <h2>My Documents</h2>
                <div className="inner">
                    {/* <button type="button" className="campaign_btn">
                        <div className="icon_blk"><img src={send} alt="" /></div>
                        Push to Zapier
                    </button> */}
                    <div className="head">
                        <div className="left_blk">
                            <h3>{campaignById?.name?.toUpperCase()} / {campaignById?.publishingPreference?.preference?.toUpperCase()}</h3>
                        </div>

                        {
                            campaignById?.publishingPreference?.preference === 'manual' &&
                            <div className="btn_zapier">
                                <button className={`${!selectedArticle ? 'disabled' : 'zapier-button'}`} onClick={handlePushToZapier}>Push to Zapier</button>
                            </div>
                        }

                        {/* <div className="right_sec">
                            <div className="input_blk">
                                <input type="search" placeholder="Search" />
                                <i className="icon fa fa-solid fa-search" />
                            </div>
                            <div className="sort_blk">
                                Sort By:
                                <select>
                                    <span>Sort By</span>Newest
                                    <option>oldest</option>
                                    <option>by date</option>
                                    <option>by file type</option>
                                </select>
                            </div>
                        </div> */}
                    </div>
                    {
                        campaignLoader ?
                            <div className="loaderWrapper">
                                <DotLoader
                                    className="login-clip-loader"
                                    color={"#7F56D9"}
                                />
                                <h1
                                    style={{
                                        color: "#7F56D9",
                                        fontSize: "2.4rem",
                                    }}
                                >
                                    &nbsp;&nbsp;&nbsp;&nbsp;Please wait...
                                </h1>
                            </div>
                            :
                            <div className="document_sec">
                                <div className="document_card_blk">
                                    {
                                        campaignById?.articles?.length === 0 ?
                                            <h1 style={{ color: 'black' }}>Sorry Nothing To Show Here.</h1>
                                            :
                                            campaignById?.articles?.map((item, index) => {
                                                return (

                                                    <div className="document_card" >
                                                        {
                                                            item?.isPublished === false && item?.publishTime === null && campaignById?.publishingPreference?.preference === 'manual' &&
                                                            <input type="radio" id='radio-action' value={item?._id} checked={selectedArticle === item?._id} onChange={(e) => setSelectedArtile(e.target.value)} />
                                                        }
                                                        <h2 onClick={() => handleViewArticle(item)}>
                                                            {item?.topic}
                                                        </h2 >
                                                        <span onClick={() => handleViewArticle(item)} className="date">{moment(item?.createdAt).format("LL")}</span>
                                                        <br />
                                                        {
                                                            item?.isPublished === true && campaignById?.publishingPreference?.preference === 'manual' &&
                                                            <span style={{cursor: "default"}} className="date">Published</span>
                                                        }
                                                        {
                                                            item?.isPublished === false && item?.publishTime !== null && campaignById?.publishingPreference?.preference === 'manual' &&
                                                            <span style={{cursor: "default"}} className="date">Publishing Article</span>
                                                        }
                                                    </div>
                                                )
                                            })
                                    }
                                </div>
                            </div>
                    }
                </div>
            </section>
        </>
    );
};

export default SelectedCampaign;
