import React from "react";
import './emailVerification.scss';
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../config/routes";

const EmailVerificationPage = () => {

    const user = useSelector((state) => state?.Authentication?.user) || localStorage.getItem('User');

    if(!user) {
        return <Redirect to={routes.home}/>
    }

    if(JSON.parse(user)?.isEmailVerified === true) {
        return <Redirect to={routes.home}/>
    }

    return (
        <>
            <div className="coming-soon">
                <h1 style={{ color: '#7F56D9', textAlign: 'center' }}>
                    Verification link has been sent to your email.
                    <br />
                    Kindly verify to continue using Trolly.ai.
                </h1>
            </div>
        </>
    )
}

export default EmailVerificationPage;
