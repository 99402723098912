import { toast } from "react-toastify";
import * as api from "../../api/api";
import { pricing } from "../type";

export const setExpiryDetail = (payload) => ({
    type: pricing.SET_EXPIRY_DETAILS,
    payload
})

export const userExpiryDetails = (userId) => (dispatch, getState) => {
    api
    .pricingExpiry(userId, {})
    .then((response) => {
        dispatch(setExpiryDetail(response?.data))
    })
    .catch((err) => {
        console.log({err})
    })
};

export const cancelSubscription = (userId) => (dispatch, getState) => {
    api
    .cancelSubscription(userId, {})
    .then((response) => {
        toast.success('Subscription Cancelled.')
        dispatch(userExpiryDetails(userId))
    })
    .catch((err) => {
        console.log({err})
    })
}

export const renewSubscription = (data, userId) => (dispatch, getState) => {
    api
    .renewSubscription(data)
    .then((response) => {
        toast.success('Subscription Renewed.')
        dispatch(userExpiryDetails(userId))
    })
    .catch((err) => {
        console.log({err})
    })
}
