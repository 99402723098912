import { bulkGeneration } from "../type";

const initialState = {
    allCampaigns: [],
    campaignLoader: false,
    campaignRedirection: false,
    createdCampaignRedirection: false,
    campaginById: {},
    campaginCountLoader: false,
    campaignCountInfo: {},
};

const BulkGeneration = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case bulkGeneration.SET_CAMPAIGN_LOADER: {
            return {
                ...state,
                campaignLoader: payload
            }
        }
        case bulkGeneration.SET_CAMPAIGN_REDIRECTION: {
            return {
                ...state,
                campaignRedirection: payload
            }
        }
        case bulkGeneration.SET_CREATED_CAMPAIGN_REDIRECTION: {
            return {
                ...state,
                createdCampaignRedirection: payload
            }
        }
        case bulkGeneration.SET_ALL_CAMPAIGNS: {
            return {
                ...state,
                allCampaigns: payload
            }
        }
        case bulkGeneration.SET_CAMPAIGN_BY_ID: {
            return {
                ...state,
                campaginById: payload
            }
        }
        case bulkGeneration.SET_CAMPAGIN_COUNT_LOADER: {
            return {
                ...state,
                campaginCountLoader: payload
            }
        }
        case bulkGeneration.SET_CAMPAIGN_COUNT: {
            return {
                ...state,
                campaignCountInfo: payload
            }
        }
        default: {
            return state;
        }
    }
}

export default BulkGeneration;
