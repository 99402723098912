import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Authentication from '../reducer/auth';
import PanelData from '../reducer/panel';
import Pricing from '../reducer/pricing';
import BulkGeneration from '../reducer/bulkGeneration';
// import UserDetails from '../reducers/userDetails';

const createRootReducer = history => combineReducers({
    router: connectRouter(history),
    Authentication,
    PanelData,
    Pricing,
    BulkGeneration,
    // UserDetails,
})

export default createRootReducer;
