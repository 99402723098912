const validate = (values) => {
    let errors = {};

    if (!values.email) {
        errors.email = "Enter your email.";
    } else if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)
    ) {
        errors.email = "Invalid email address.";
    }

    if (!values.password) {
        errors.password = "Enter your password.";
    }
    else if (values.password.length < 8 || !/[A-Z]/.test(values.password) || !/[0-9]/.test(values.password)) {
        errors.password = "Password must be at least 8 characters with 1 upper case letter and 1 number.";
    }

    return errors;
};

export default validate;
