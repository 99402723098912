import axios from "axios";
import api from "../config/api";

//Home
export const emailCapture = (payload) =>
	axios.post(api.home.emailCapture, payload);

//Authentication
export const signinUser = (payload) => axios.post(api.auth.login, payload);
export const signupUser = (payload) => axios.post(api.auth.register, payload);
export const signupUserWithRefferal = (referralCode, payload) =>
	axios.post(api.auth.registerWithRefferal(referralCode), payload);
export const loginUserWithGoogle = (payload) =>
	axios.post(api.auth.loginWithGoogle, payload);
export const forgotPassword = (payload) =>
	axios.post(api.auth.forgotPassword, payload);
export const resetPassword = (payload) =>
	axios.post(api.auth.resetPassword, payload);
export const verifyEmail = (token, payload) =>
	axios.post(api.auth.verifyEmail(token), payload);

// user
export const updateUserPassword = (payload) =>
	axios.put(api.user.updateUserPassword, payload);

export const updateCurrentUser = (userId, payload) => {
	axios.put(api.user.updateUser(userId), payload);
};

export const updateName = (userId, payload, config) =>
	axios.put(api.user.updateName(userId), payload, config);

//Panel
export const generateFancyHeadlines = (payload, config) =>
	axios.post(api.panel.generateFancyTitles, payload, config);
// export const generateFancyHeadlines = (title, config) => axios.get(api.panel.generateFancyTitles(title), config);

export const generateTitleOutlines = (payload, config) =>
	axios.post(api.panel.generateTitleOutlines, payload, config);
export const generateArticle = (payload, config) =>
	axios.post(api.panel.generateArticle, payload, config);
export const generateLongArticle = (payload, config) =>
	axios.post(api.panel.generateLongArticle, payload, config);
export const generateArticleSummary = (payload, config) =>
	axios.post(api.panel.articleSummary, payload, config);
export const getSeoScore = (payload, config) =>
	axios.post(api.panel.getSeoScore, payload, config);

//save-article
export const saveArticleData = (payload) =>
	axios.post(api.panel.saveArticle, payload);

//save-article-for-user
export const saveArticleForUser = (payload) =>
	axios.post(api.panel.saveArticleForUser, payload);

//save-edited-article
export const saveEditedArticle = (id, payload) =>
	axios.put(api.panel.saveEditedArticle(id), payload);

//get-user-articles
export const getUserArticles = (userId, config) =>
	axios.post(api.panel.getArticles(userId), config);
export const getUserArticleById = (id) =>
	axios.post(api.panel.getArticleById(id));

//user-expiry-details
export const pricingExpiry = (userId, payload) =>
	axios.post(api.pricing.expiryStatus(userId), payload);

//cancel-subscription
export const cancelSubscription = (userId, payload) =>
	axios.post(api.pricing.cancelSubscription(userId), payload);

//re-new-subscription
export const renewSubscription = (payload) =>
	axios.post(api.pricing.renewSubscription, payload);

//get-user-detail
export const getUserDetail = (userId, config, payload) =>
	axios.post(api.user.getUserDetail(userId), config, payload);

//campaign
export const getAllCampaigns = (userId) =>
	axios.get(api.bulkGeneration.getAllCampaigns(userId));

//campaign-by-id
export const getCampaignById = (campaignId) =>
	axios.get(api.bulkGeneration.getCampaignById(campaignId));

//post-campaign
export const postCampaign = (payload) =>
	axios.post(api.bulkGeneration.postCampaign, payload);

//add-member
export const addTeamMember = (email, username, config, payload) =>
	axios.post(api.addTeamMember.addMember(email, username), config, payload);

//view-team-member
export const viewTeamMembers = (config) =>
	axios.get(api.addTeamMember.viewTeamMembers, config);

//delete-member
export const deleteTeamMember = (teamMemberId, config) =>
	axios.delete(api.addTeamMember.deleteTeamMember(teamMemberId), config);

//campagin-count
export const campaginCount = (userId) =>
	axios.get(api.bulkGeneration.campaginCount(userId));

//zapier-push
export const pushToZapier = (articleId, payload, config) =>
	axios.patch(api.zapier.pushToZapier(articleId), payload, config);
