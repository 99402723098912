import React from "react";
import Header from "../../containers/Header/Header";
import "./contactUs.scss";
import Footer from "../../containers/Footer/Footer";

const ContactUs = () => {
  return (
    <>
      <Header />
      <section id="contact_us">
        <div className="heading">
          <h2 className="contact-heading">Contact Us</h2>
          <p className="contact-description">
            Any question or remarks? Just write us a message!
          </p>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="main-section">
              <h1 className="">Let's Talk</h1>
              <p className="description">
                Write something to start conversation!
              </p>
              <h3 className="heading-h3">Email</h3>
              <p className="description">trolly@taimoor.xyz</p>
              <h3 className="heading-h3">Socials</h3>
              <a
                className="link"
                href="http://www.twitter.com/octalabsxyz"
                target="_blank"
              >
                Twitter
              </a>
              <a
                className="link"
                href="http://www.facebook.com/octalabsxyz"
                target="_blank"
              >
                Facebook
              </a>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <form className="contact_form">
              <label>Name</label>
              <div className="input_blk">
                <input type="text" placeholder="John Doe" />
              </div>
              <label>Email</label>
              <div className="input_blk">
                <input type="email" placeholder="abc@xyz.com" />
              </div>
              <label>Message</label>
              <div className="input_blk">
                <textarea placeholder="Message" className="text_area"></textarea>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;
