import React, { useState } from "react";

import "./settings.scss";
import { Link, Redirect } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { updateName } from "../../../redux/actions/panel";
import { toast } from "react-toastify";
import { resetPassword } from "../../../redux/actions/auth";
import * as bcrypt from "bcryptjs";

const Settings = () => {
	const dispatch = useDispatch();

	const user =
		useSelector((state) => state?.Authentication?.user) ||
		localStorage.getItem("User");

	const {
		email,
		name,
		_id: userId,
		isSSO,
		authUserPasswordEnabled,
		isEmailVerified,
		password: currentPassword,
	} = JSON.parse(user);

	const [editName, setEditName] = useState(false);
	const [changePassword, setChangePassword] = useState(false);
	const [formValues, setFormValues] = useState({
		name: name,
		email: "",
		password: "",
		newPassword: "",
		rePassword: "",
	});

	const token = useSelector((state) => state?.Authentication?.token);

	if (user === undefined || token === undefined) {
		return <Redirect to={routes.auth.logIn} />;
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		const payload = {
			name: formValues.name,
		};

		if (!changePassword) {
			dispatch(updateName(userId, payload));
			setEditName(false);
		}

		if (changePassword) {
			if (
				!formValues.password ||
				!formValues.newPassword ||
				!formValues.rePassword
			) {
				toast.error("All fields are required");
				return;
			}

			if (formValues.newPassword !== formValues.rePassword) {
				toast.error("Password does not match");
				return;
			}

			const isPasswordMatch = bcrypt.compareSync(
				formValues.password,
				currentPassword
			);

			if (!isPasswordMatch) {
				toast.error("Current password is incorrect");
				return;
			}

			const payload = {
				password: formValues.newPassword,
				token: token,
			};

			dispatch(resetPassword(payload));

			setChangePassword(false);

			setFormValues({
				...formValues,
				password: "",
				newPassword: "",
				rePassword: "",
			});

			return;
		}
	};

	return (
		<>
			<section id="setting">
				<div className="site_container">
					<h2 className="title">
						{changePassword ? "Change Password" : "Account Settings"}
					</h2>

					<form className="setting_form" onSubmit={handleSubmit}>
						<div className="image_blk">
							<div className="img_blk">
								<span>{name?.charAt(0)}</span>
							</div>
						</div>
						{!changePassword && (
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									gap: "5px",
								}}
							>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
										gap: "20px",
									}}
								>
									<label>User Name</label>
									{!editName && (
										<button
											type="button"
											onClick={() => {
												setEditName(true);
												setChangePassword(false);
											}}
											style={{
												border: "none",
												background: "none",
												color: "blue",
												cursor: "pointer",
												fontSize: "15px",
											}}
										>
											Edit
										</button>
									)}
								</div>
								<div className="input_blk">
									<input
										type="text"
										value={formValues.name}
										placeholder="Enter Your Username"
										onChange={(e) =>
											setFormValues({ ...formValues, name: e.target.value })
										}
										disabled={!editName}
									/>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										width: "100%",
										gap: "5px",
									}}
								>
									<label>Email</label>
									<div className="input_blk">
										<input
											type="text"
											value={email}
											placeholder="Enter Your Username"
											disabled={true}
										/>
									</div>
								</div>
							</div>
						)}
						{changePassword && (
							<>
								{!authUserPasswordEnabled &&
								isSSO &&
								!isEmailVerified ? null : (
									<>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												width: "100%",
												gap: "5px",
											}}
										>
											<label>Enter current Password</label>
											<div className="input_blk">
												<input
													type="password"
													value={formValues.password}
													placeholder="Enter Your password"
													onChange={(e) =>
														setFormValues({
															...formValues,
															password: e.target.value,
														})
													}
												/>
											</div>
										</div>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												width: "100%",
												gap: "5px",
											}}
										>
											<label>Enter New Password</label>
											<div className="input_blk">
												<input
													type="password"
													value={formValues.newPassword}
													placeholder="Enter Your password"
													onChange={(e) =>
														setFormValues({
															...formValues,
															newPassword: e.target.value,
														})
													}
												/>
											</div>
										</div>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												width: "100%",
												gap: "5px",
											}}
										>
											<label>Re-Enter Password</label>
											<div className="input_blk">
												<input
													type="password"
													value={formValues.rePassword}
													placeholder="Enter Your password"
													onChange={(e) =>
														setFormValues({
															...formValues,
															rePassword: e.target.value,
														})
													}
												/>
											</div>
										</div>
									</>
								)}
							</>
						)}
						{!changePassword && !editName && (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									width: "100%",
								}}
							>
								<Link className="pricing_link" to={routes.panel.pricing}>
									<button
										type="button"
										style={{
											border: "none",
											background: "none",
											color: "blue",
											cursor: "pointer",
											fontSize: "14px",
										}}
									>
										Change Your Plan{" "}
									</button>
								</Link>
								{!authUserPasswordEnabled &&
								isSSO &&
								!isEmailVerified ? null : (
									<button
										type="button"
										onClick={() => {
											setChangePassword(true);
											setEditName(false);
										}}
										style={{
											border: "none",
											background: "none",
											color: "blue",
											cursor: "pointer",
											fontSize: "14px",
										}}
									>
										Change Password
									</button>
								)}
							</div>
						)}
						{(changePassword || editName) && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
									gap: "20px",
								}}
							>
								<button
									className="cancel_btn"
									onClick={() => {
										setEditName(false);
										setChangePassword(false);
									}}
								>
									Cancel
								</button>
								<button className="save_btn" type="submit">
									Save Changes
								</button>
							</div>
						)}
					</form>
				</div>
			</section>
		</>
	);
};

export default Settings;
