import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import "./forgotPassword.scss";
import { TextField } from "../../Common/TextField";
import routes from "../../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../../redux/actions/auth";
import { DotLoader } from "react-spinners";

const ForgotPassword = () => {

  const dispatch = useDispatch();

  const user = useSelector((state) => state?.Authentication?.user);

  const token = useSelector((state) => state?.Authentication?.token);

  const redirectSuccess = useSelector((state) => state?.Authentication?.redirectSuccess);
  const authLoader = useSelector((state) => state?.Authentication?.authLoader);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [redirect, setRedirect] = useState(false);

  const validate = (value) => {
    let errors = "";
    if (!value) {
      errors = "Email is required.";
    } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
    ) {
      errors = "Invalid email address.";
    }
    return errors;
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();

    const validationError = validate(email);
    setError(validationError);

    if (!validationError) {
      localStorage.setItem('Forgot-Email', email)
      const payload = {
        email: email,
      }
      dispatch(forgotPassword(payload))
      setRedirect(true);
    }
  };

  if (redirect && redirectSuccess) {
    return <Redirect to={{
      pathname: routes.auth.resetPassword,
      state: {
        user: email
      }
    }} />
  }

  if (user && token) {
    return <Redirect to={routes.panel.dashboard} />
  }

  return (
    <>
      <section id="forgot">
        <div className="logo_blk">
          <img src={logo} alt="logo" />
        </div>

        <form className="signup_form">
          <h2>Forgot your password</h2>
          <p className="grey_text">
            Enter your registered email address to reset the password
          </p>

          <label>Email</label>
          <div className="input_blk">
            <TextField
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="e.g xyz@gmail.com"
            />
          </div>
          {error && <p className="form-error-message">{error}</p>}
          {
            authLoader ?
              <div className="loaderWrapper">
                <DotLoader className="login-clip-loader" color={"#CC34E4"} />
                <h1
                  style={{
                    color: "#CC34E4",
                    fontSize: "2.4rem",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Sending a verification link...
                </h1>
              </div>
              :
              <button type="button" className="signup_btn" onClick={handleForgotPassword}>
                Continue
              </button>
          }
        </form>
      </section>
    </>
  );
};

export default ForgotPassword;
