import { panelData } from "../type";

const initialState = {
	title: localStorage.getItem("Title") || "",
	fancyTitle: localStorage.getItem("Fancy-Headline") || {},
	fancyTitleCreationLoader: false,
	keywords: localStorage.getItem("Keywords") || [],
	titleOutline: [],
	article: localStorage.getItem("Article-Generated") || {},
	saveArticle: localStorage.getItem("Saved-Article") || {},
	saveUserArticle: false,
	userArticles: [],
	outlineLoader: false,
	articleLoader: false,
	articleById: localStorage.getItem("Article-By-Id") || {},
	articleSeoScore: localStorage.getItem("Article-SEO") || {},
	articleByIdLoader: false,
	panelLoader: false,
	viewArticleByIdLoader: false,
	articleSummary: "",
	teamMembersList: [],
};

const PanelData = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case panelData.SET_TITLE: {
			return {
				...state,
				title: payload,
			};
		}
		case panelData.SET_FANCY_TITLES: {
			return {
				...state,
				fancyTitle: payload,
			};
		}
		case panelData.SET_FANCY_TITLE_LOADER: {
			return {
				...state,
				fancyTitleCreationLoader: payload,
			};
		}
		case panelData.SET_KEYWORDS: {
			return {
				...state,
				keywords: payload,
			};
		}
		case panelData.SET_TITLE_OUTLINE: {
			return {
				...state,
				titleOutline: payload,
			};
		}
		case panelData.SET_ARTICLE: {
			return {
				...state,
				article: payload,
			};
		}
		case panelData.SET_SAVE_ARTICLE: {
			return {
				...state,
				saveArticle: payload,
			};
		}
		case panelData.SET_SAVE_ARTICLE_FOR_USER: {
			return {
				...state,
				saveUserArticle: payload,
			};
		}
		case panelData.SET_USER_ARTICLES: {
			return {
				...state,
				userArticles: payload,
			};
		}
		case panelData.SET_OUTLINE_CREATE_LOADER: {
			return {
				...state,
				outlineLoader: payload,
			};
		}
		case panelData.SET_ARTICLE_CREATE_LOADER: {
			return {
				...state,
				articleLoader: payload,
			};
		}
		case panelData.SET_ARTICLE_BY_ID: {
			return {
				...state,
				articleById: payload,
			};
		}
		case panelData.SET_ARTICLE_BY_ID_LOADER: {
			return {
				...state,
				articleByIdLoader: payload,
			};
		}
		case panelData.SET_PANEL_LOADER: {
			return {
				...state,
				panelLoader: payload,
			};
		}
		case panelData.SET_VIEW_ARTICLE_BY_ID_LOADER: {
			return {
				...state,
				viewArticleByIdLoader: payload,
			};
		}
		case panelData.SET_ARTICLE_SUMMARY: {
			return {
				...state,
				articleSummary: payload,
			};
		}
		case panelData.SET_TEAM_MEMBER_LIST: {
			return {
				...state,
				teamMembersList: payload,
			};
		}
		case panelData.SET_ARTICLE_SEO_SCORE: {
			return {
				...state,
				articleSeoScore: payload,
			};
		}
		default: {
			return state;
		}
	}
};

export default PanelData;
