import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../config/routes";

const WhatsNext = () => {
  return (
    <section id="what_next">
      <div className="site_container">
        <div className="inner">
          <h2>Get to know whats next for Trolly</h2>
          <p>
            Unleash the power of AI to write faster, dream bigger, and boost
            your creativity. It's like magic!
          </p>
          <button type="button" className="demo_btn icon_blk">
            <Link
              style={{ color: "white" }}
              to={routes.auth.signUp}
              type="button"
            >
              Start Trial
            </Link>
          </button>
        </div>
      </div>
    </section>
  );
};

export default WhatsNext;
