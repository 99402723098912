import React, { useState } from "react";
import PanelHeader from "../containers/PanelHeader/PanelHeader";
import PanelSideBar from "../containers/PanelSidebar/PanelSidebar";

const PanelLayout = ({ children }) => {
	const [sidebarActive, setSidebarActive] = useState(false);

	const handleSideBarClick = () => {
		setSidebarActive(!sidebarActive);
	};


	return (
		<>
			<div className="">
				<PanelSideBar isActive={sidebarActive} isClicked={handleSideBarClick} />
				<div className="page_container">
					<PanelHeader clicked={handleSideBarClick} />
					{children}
				</div>
			</div>
		</>
	);
};

export default PanelLayout;
