import React from "react";

import "./PanelHeader.scss";

const PanelHeader = ({clicked}) => {
  return (
    <>
      <header id="panel_header">
        <i className="fa fa-solid fa-bars" onClick={clicked}/>
      </header>
    </>
  );
};

export default PanelHeader;
