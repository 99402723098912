import React, { useEffect, useState } from 'react';
import './viewCampaignArticle.scss';

const ViewCampaignArticle = () => {

    const valueToParse = localStorage?.getItem('Value');
    const value = valueToParse ? JSON.parse(valueToParse) : valueToParse;

    const [articleExplanation, setArticleExplanation] = useState('');

    useEffect(() => {
        let removed = [
            ...new Set(
                value?.explainedHeadings
                    ?.replaceAll(":", "\n")
                    ?.split("\n")
            ),
        ];

        removed = removed.filter((item) => item !== "");

        removed = removed?.map((str) => {
            if (str?.length < 150) {
                return `<h3>${str}</h3>\n`;
            } else {
                return `<p>${str}<p>\n`;
            }
        });

        const explanation = removed?.join("");

        setArticleExplanation(explanation);
    }, [value])
    return (
        <>
            <section id="preview-article">
                <h2 style={{textAlign: 'center'}}>Preview Article</h2>
                <div className="dashboard_cards_sec">
                    <div className="row m-0">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h2 style={{ color: 'black' }}>Meta Description</h2>
                            <p style={{ color: 'black' }}>{value?.metaDescription}</p>
                            <br />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h2 style={{ color: 'black' }}>Introduction</h2>
                            <p style={{ color: 'black' }}>{value?.introduction}</p>
                            <br />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h2 style={{ color: 'black' }}>Explanation</h2>
                            <div style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: articleExplanation }} />
                            <br />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h2 style={{ color: 'black' }}>Conclusion</h2>
                            <p style={{ color: 'black' }}>{value?.conclusion}</p>
                            <br />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ViewCampaignArticle;
