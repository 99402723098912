import { toast } from "react-toastify";
import * as api from "../../api/api";
import { setAuthLoader } from "./auth";

export const emailCapture = (data) => (dispatch) => {
  dispatch(setAuthLoader(true));
  api
    .emailCapture(data)
    .then((res) => {
      dispatch(setAuthLoader(false));
      toast.success("Thank you for submitting your email!");
    })
    .catch((err) => {
      dispatch(setAuthLoader(false));
      toast.error("Sorry :( Try Again!");
    });
};
