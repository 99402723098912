import React, { useEffect, useState } from "react";
import '../WizardPage/WizardPage.scss';
import { generateFancyHeadline, generateTitleOutline, setArticle, setTitleOutline } from "../../../redux/actions/panel";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../config/routes";
import { DotLoader } from "react-spinners";

const FancyTitles = () => {
    const [selectedTitle, setSelectedTitle] = useState("");
    const [error, setError] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [goBackToWizard, setGoBackToWizard] = useState(false);
    const [fancyHeadlines, setFancyHeadlines] = useState(null)

    let keyWords = JSON.parse(localStorage.getItem("Keywords"));
    const panelLoader = useSelector((state) => state?.PanelData?.panelLoader);

    const language = localStorage.getItem('Language');

    const dispatch = useDispatch();

    const token = useSelector((state) => state?.Authentication?.token);
    const user =
        useSelector((state) => state?.Authentication?.user) 

    const refreshed = localStorage?.getItem('refreshed');

    useEffect(() => {
        if(refreshed !== 'true') {
            localStorage.setItem('refreshed', true);
            window.location.reload();
        }
    }, [])

    useEffect(() => {
        dispatch(setTitleOutline([]))
        dispatch(setArticle({}));
        localStorage.removeItem('Article-Generated');
        localStorage.removeItem('Saved-Article');
        localStorage.removeItem('Article-By-Id');
        localStorage?.removeItem("Article-Generate-Check");
        localStorage.removeItem("123");
    }, [])

    const fancyHeadline =
        useSelector((state) => state?.PanelData?.fancyTitle)


    useEffect(() => {
        if (Object.keys(fancyHeadline).length === 0) {
            setGoBackToWizard(true);
        } else {
            let fancyHeadlinesData = fancyHeadline?.headlines
                ? fancyHeadline
                : JSON.parse(fancyHeadline);
            setFancyHeadlines(fancyHeadlinesData)
        }
    }, [fancyHeadline])

    const outlineLoader = useSelector((state) => state?.PanelData?.outlineLoader);

    const handleSelectHeading = (e, value) => {
        setError("");
        setSelectedTitle(value);
    };

    const handleGenerateOutline = (e) => {
        e.preventDefault();

        if (selectedTitle === "") {
            setError("Please select one title to create an outline.");
        } else {
            localStorage.setItem("Selected-Fancy-Title", selectedTitle);
            let newArr = [];

            for (let i = 0; i < keyWords.length; i++) {
                newArr[i] = (keyWords[i])
            }
            let stringKeywords = newArr?.join(",");
            const payload = {
                topic: selectedTitle,
                language: language,
                keywords: stringKeywords,
            }
            dispatch(generateTitleOutline(payload));
            setRedirect(true);
        }
    };

    const title = localStorage.getItem("Title");
    const handleRegenerateHeadlines = (e) => {
        e.preventDefault();
        setSelectedTitle('');
        const payload = {
            topic: title,
            language: language
        }
        dispatch(generateFancyHeadline(payload));
    }

    if (redirect && outlineLoader) {
        return <Redirect to={routes.panel.titleOutline} />;
    }

    if (user === undefined || token === undefined) {
        return <Redirect to={routes.auth.logIn} />
    }

    if (goBackToWizard) {
        return <Redirect to={routes.panel.wizard} />
    }
    return (
        <section id="WizardPage">
            <h2>Wizard</h2>

            <div className="inner">
                <h3>Generate SEO Optimized Article</h3>
                <p>Please fill the form below to receive a well optimized article</p>
                <form className="headline_form">
                    <hr />
                    <label className="mb-4">Select Headline</label>

                    <ul className="headline_list">
                        {fancyHeadlines?.headlines?.map((item, index) => {
                            return (
                                    <li key={index} >
                                        <label className="label">
                                            <input
                                                type="radio"
                                                id={item}
                                                name="selected"
                                                onChange={(e) => handleSelectHeading(e, item)}
                                            />
                                        </label>
                                        <p>{item}</p>
                                    </li>
                            );
                        })}
                        {error && <div className="error-message mb-4">{error}</div>}
                    </ul>
                </form>
                {panelLoader ? (
                    <div className="loaderWrapper">
                        <DotLoader className="login-clip-loader" color={"#7F56D9"} />
                        <h1
                            style={{
                                color: "#7F56D9",
                                fontSize: "2.4rem",
                            }}
                        >
                            &nbsp;&nbsp;&nbsp;&nbsp;Please wait...
                        </h1>
                    </div>
                ) : (
                    <div className="btns_blk">
                        <button
                            className="re_generate_title_btn"
                            onClick={handleRegenerateHeadlines}
                        >
                            Regenerate Headlines
                        </button>
                        <button
                            className="generate_title_btn"
                            onClick={handleGenerateOutline}
                        >
                            Generate Outline
                        </button>
                    </div>
                )}
            </div>
        </section>
    )
}

export default FancyTitles;
