import React, { useState } from "react";
import logo from "../../../assets/images/logo.png";
import "./resetPassword.scss";
import { TextField } from "../../Common/TextField";
import { Redirect} from "react-router-dom";
import routes from "../../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, setRedirectSuccess } from "../../../redux/actions/auth";
import { useEffect } from "react";
import { DotLoader } from "react-spinners";

const ResetPassword = (props) => {

  const dispatch = useDispatch();

  const user = useSelector((state) => state?.Authentication?.user);

  const token = useSelector((state) => state?.Authentication?.token);

  const redirectSuccess = useSelector((state) => state?.Authentication?.redirectSuccess);
  console.log(redirectSuccess)

  useEffect(() => { //this to be think about
    setRedirectSuccess(false)
  }, [])

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);

  const resetEmail = new URLSearchParams(props?.location?.search).get('token');
  const authLoader = useSelector((state) => state?.Authentication?.authLoader);

  const validate = (newPassword, confirmPassword) => {
    let errors = "";

    if (!newPassword) {
      errors = "Enter your password.";
    } else if (
      newPassword.length < 8 ||
      !/[A-Z]/.test(newPassword) ||
      !/[0-9]/.test(newPassword)
    ) {
      errors =
        "Password must be at least 8 characters with 1 upper case letter and 1 number.";
    } else if (confirmPassword !== newPassword) {
      errors = "Passwords do not match.";
    } else {
      errors = "";
    }
    return errors;
  };

  const handleResetPassword = (e) => {
    e.preventDefault();

    const validationError = validate(newPassword, confirmPassword);
    setError(validationError);
    if (!validationError) {
      const payload = {
        password: newPassword,
        token: resetEmail
      };
      dispatch(resetPassword(payload));
    }
  };

  if (resetEmail === null) {
    return <Redirect to={routes.auth.logIn} />;
  }

  if (user && token) {
    return <Redirect to={routes.panel.dashboard} />
  }

  if (redirectSuccess) {
    return <Redirect to={routes.auth.logIn} />
  }

  return (
    <>
      <section id="login">
        <div className="logo_blk">
          <img src={logo} alt="logo" />
        </div>

        <form className="signup_form">
          <h2>Reset Your Password</h2>
          <label>Enter New Password</label>
          <div className="input_blk">
            <TextField
              name="newPassword"
              type={passwordVisibility ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter your new password"
            />
            <i
              className={`passwordVisibilityReset fa ${passwordVisibility ? "fa-eye icon_blk" : "fa-eye-slash icon_blk"
                }`}
              onClick={() => setPasswordVisibility(!passwordVisibility)}
            />
            {error && !error.includes("match") && (
              <p className="form-error-message">{error}</p>
            )}
          </div>

          <label>Confirm Password</label>
          <div className="input_blk mb-2">
            <TextField
              name="confirmNewPassword"
              type={confirmPasswordVisibility ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Re-enter your new password"
            />
            <i
              className={`passwordVisibilityReset fa ${confirmPasswordVisibility
                ? "fa-eye icon_blk"
                : "fa-eye-slash icon_blk"
                }`}
              onClick={() =>
                setConfirmPasswordVisibility(!confirmPasswordVisibility)
              }
            />
            {error && error.includes("match") && (
              <p className="form-error-message">{error}</p>
            )}
          </div>
          {
            authLoader ?
              <div className="loaderWrapper">
                <DotLoader className="login-clip-loader" color={"#CC34E4"} />
                <h1
                  style={{
                    color: "#CC34E4",
                    fontSize: "2.4rem",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Updating your password...
                </h1>
              </div>
              :
             
                <button
                type="button"
                className="signup_btn"
                onClick={handleResetPassword}
              >
                Confirm
              </button>
              
          }
        </form>
      </section>
    </>
  );
};

export default ResetPassword;
