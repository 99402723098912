import routes from "../config/routes";
import zapierIcon from "../assets/images/zapier_icon.png";
import homeIcon from '../assets/images/home-icon.svg';
import newDocIcon from '../assets/images/new-doc-icon.svg';
import myDocIcon from '../assets/images/my-docs-icon.svg';
import bulkIcon from '../assets/images/bulkIcon.svg';
import apiIcon from '../assets/images/apiIcon.svg';
import pricingIcon from '../assets/images/pricing-icon.svg';
import settingsIcon from '../assets/images/setting-icon.svg';

export const SidebarItems = [
  {
    id: "0",
    route: routes.panel.dashboard,
    name: "Home",
    img: homeIcon,
  },
  {
    id: "1",
    route: routes.panel.wizard,
    name: "New Document",
    img: newDocIcon,
  },
  {
    id: "2",
    route: routes.panel.documents,
    name: "My Documents",
    img: myDocIcon,
  },
  {
    id: "3",
    route: routes.panel.bulkGeneration,
    name: "Bulk Generation",
    img: zapierIcon,
  },
  {
    id: "33",
    route: routes.panel.zapier.zapier,
    name: "Integrations",
    img: bulkIcon,
  },
  {
    id: "4",
    route: routes.panel.apiDocumentation,
    name: "API Documentation",
    img: apiIcon,
  },
  {
    id: '5',
    route: routes.panel.pricing,
    name: 'Billing',
    img: pricingIcon,
  },
  {
    id: '6',
    route: routes.panel.setting,
    name: 'Settings',
    img: settingsIcon,
  },
];
