import { toast } from "react-toastify";
import * as api from "../../api/api";
import { bulkGeneration } from "../type";

export const setAllCampaigns = (payload) => ({
  type: bulkGeneration.SET_ALL_CAMPAIGNS,
  payload,
});

export const setCampaignLoader = (payload) => ({
  type: bulkGeneration.SET_CAMPAIGN_LOADER,
  payload,
});

export const setCampaignRedirection = (payload) => ({
  type: bulkGeneration.SET_CAMPAIGN_REDIRECTION,
  payload,
});

export const setCreatedCampaignRedirection = (payload) => ({
  type: bulkGeneration.SET_CREATED_CAMPAIGN_REDIRECTION,
  payload,
});

export const setCampaignById = (payload) => ({
  type: bulkGeneration.SET_CAMPAIGN_BY_ID,
  payload,
});

export const setCampaignCount = (payload) => ({
  type: bulkGeneration.SET_CAMPAIGN_COUNT,
  payload,
});

export const setCampaginCountLoader = (payload) => ({
  type: bulkGeneration.SET_CAMPAGIN_COUNT_LOADER,
  payload,
});

export const getAllCampaigns = (userId) => (dispatch) => {
  dispatch(setCampaignLoader(true));

  api
    .getAllCampaigns(userId)
    .then((response) => {
      dispatch(setAllCampaigns(response?.data?.campaigns));
      dispatch(setCampaignLoader(false));
    })
    .catch((err) => {
      dispatch(setCampaignLoader(false));
    });
};

export const getCampaignById = (campaignId) => (dispatch) => {
  dispatch(setCampaignLoader(true));
  dispatch(setCampaignRedirection(false));

  api
    .getCampaignById(campaignId)
    .then((response) => {
      dispatch(setCampaignById(response?.data?.campaign));
      dispatch(setCampaignRedirection(true));
      dispatch(setCampaignLoader(false));
    })
    .catch((err) => {
      dispatch(setCampaignRedirection(false));
      dispatch(setCampaignLoader(false));
    });
};

export const createCampaign = (data) => (dispatch) => {
  // dispatch(setCampaignLoader(true));
  dispatch(setCreatedCampaignRedirection(true));
  api
    .postCampaign(data)
    .then((response) => {

      dispatch(setCreatedCampaignRedirection(false));
      toast.success("Campaign Created.");
      if (data?.role === "Team Member") {
        dispatch(getAllCampaigns(data?.parentUserId));
      }
      if (data?.role !== "Team Member") {
        dispatch(getAllCampaigns(data?.userId));
      }
      // dispatch(setCampaignLoader(false));
    })
    .catch((err) => {
      dispatch(setCreatedCampaignRedirection(false));
      // dispatch(setCampaignLoader(false));
    });
};

export const campaginCount = (userId) => (dispatch, getState) => {
  dispatch(setCampaginCountLoader(true));

  api
    .campaginCount(userId)
    .then((response) => {
      dispatch(setCampaignCount(response?.data));
      dispatch(setCampaginCountLoader(false));
    })
    .catch((err) => {
      dispatch(setCampaginCountLoader(false));
      console.log({ err });
    });
};

export const articlePushToZapier = (articleId, campaignId) => (dispatch, getState) => {
  dispatch(setCampaignLoader(true));
  const state = getState();
  const token = state?.Authentication?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  api
    .pushToZapier(articleId, {}, config)
    .then((response) => {
      dispatch(setCampaignLoader(false));
      toast.success("Article Published.");
      dispatch(getCampaignById(campaignId))
    })
    .catch((err) => {
      dispatch(setCampaignLoader(false));
    });
};
