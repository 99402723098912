import React from "react";
import "./termsAndConditions.scss";

const TermsAndConditions = () => {
  return (
    <>
      <section id="privacy_terms">
        <h2>Policy</h2>

        <div className="inner">
          <h3>Fair Use Policy</h3>
          <div className="text_blk">
            <h4>Introduction</h4>
            <p>
              This Fair Use Policy ("Policy") governs the use of Trolly.ai ("the
              Service") provided by Trolly.ai/OctaLabs. By using our Service,
              users ("You", "Your") agree to adhere to this Policy. We reserve
              the right to modify this Policy at any time and will make efforts
              to communicate such changes to our users.
            </p>
          </div>

          <div className="text_blk">
            <h4>Purpose</h4>
            <p>
              The purpose of this Fair Use Policy is to ensure that all of our
              users have equitable access to the tools, resources, and bandwidth
              offered by our Service.
            </p>
          </div>
          <div className="text_blk">
            <h4>General</h4>
            <p>
              Usage Limits While we strive to provide unlimited access to our
              Service, there are certain usage limits in place to prevent abuse:
            </p>
            <p>
              a. <span style={{ color: 'black', fontWeight: '600' }}>API Calls:</span> There is no limit of API calls if the service is not exhausted while using.
            </p>
            <ul style={{ paddingLeft: '20px' }}>
              <li style={{ listStyleType: 'disc', marginTop: '10px', color: 'black', fontSize: '2.2rem' }}>
                <span style={{ color: 'black', fontWeight: '600'}}>Appsumo Pro Package,</span> While our SaaS offers unlimited API calls post-review,
                new accounts are limited to 250 calls monthly during review, and excessive use
                detected by our system may result in account suspension.
              </li>
            </ul>
            <br/>
            <h4 >
              Prohibited
            </h4>
            <p style={{ fontWeight: '600', marginTop: '10px' }}>
              Users must not:
            </p>
            <p>
              a. Engage in activities that degrade the performance of our Service for other users.
              <br />
              b. Use our Service for illegal or illicit purposes.<br />
              c. Attempt to gain unauthorized access to our Service or any related systems.<br />
              d. Distribute malware, viruses, or harmful software through our platform.<br />
              e. Use our Service for spamming, phishing, or similarly malicious activities.<br />
              f. Engage in any form of automated data scraping without our express consent.<br />
              g. Share their account access with multiple users without purchasing the appropriate licensing tier.<br />
            </p>
            <br />
            <p style={{ fontWeight: '600', marginTop: '10px' }}>
              Enforcement
            </p>
            <p>
              a. We actively monitor usage patterns for any violations of this Policy.<br />
              b. In the event of a suspected violation, we will reach out to the user for clarification or to request remedial action.<br />
              c. Users found in violation of this Policy may have their access to our Service suspended or terminated.<br />
              d. In extreme cases, we reserve the right to pursue legal action against users who severely misuse our Service.<br />
            </p>
            <br />
            <p style={{ fontWeight: '600', marginTop: '10px' }}>
              Review and Changes
            </p>
            <p>
              a. We may periodically review this Policy and make adjustments based on the feedback from our user community and technological advancements.<br />
              b. Any changes made will be communicated to our users at least a few days before they take effect.<br />
            </p>
            <br />
            <p style={{ fontWeight: '600', marginTop: '10px' }}>
              Contact
            </p>
            <p>
              For any questions or concerns regarding this Fair Use Policy, please contact us at [contact@trolly.ai] ot [trolly@taimoor.xyz].
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
