import React from "react";
import "./myDocuments.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
	getArticleById,
	getUserArticles,
} from "../../../redux/actions/panel";
import moment from "moment";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import routes from "../../../config/routes";
import { ClimbingBoxLoader, DotLoader } from "react-spinners";
import Badge from "react-bootstrap/Badge";

const MyDocuments = () => {
	const dispatch = useDispatch();
	const [redirect, setRedirect] = useState(false);
	const [redirectState, setRedirectState] = useState({});
	const user = useSelector((state) => state?.Authentication?.user) 
	const userId = JSON.parse(user)?._id;
	const role = JSON.parse(user)?.role;

	const token = useSelector((state) => state?.Authentication?.token);
	const viewArticleByIdLoader = useSelector(
		(state) => state?.PanelData?.viewArticleByIdLoader
		);
		const panelLoader = useSelector((state) => state?.PanelData?.panelLoader);
		
		useEffect(() => {
			if (role === "Team Member") {
				const parentUserId = JSON.parse(user)?.parentUserId;
				dispatch(getUserArticles(parentUserId));
			}
			if (role !== "Team Member" && userId) {
         dispatch(getUserArticles(userId));
   }
		}, [dispatch, role]);
		
	const userArticles = useSelector((state) => state?.PanelData?.userArticles);
	const handleViewItem = (value) => {
		if (value) {
			setRedirectState(value);
			dispatch(getArticleById(value?.dataId));
			setRedirect(true);
		}
	};

	if (redirect && viewArticleByIdLoader) {
		return (
			<Redirect
				push
				to={{
					pathname: routes.panel.viewArticle,
					state: { itemDetail: redirectState },
				}}
			/>
		);
	}
	if (user === undefined || token === undefined) {
		return <Redirect push to={routes.auth.logIn} />;
	}
	return (
		<>
			<section id="MyDocuments">
				<div className="top">
					<h2>My Documents</h2>
				</div>
				<div className="document_sec">
					{panelLoader && redirect ? (
						<div className="loaderWrapper">
							<DotLoader className="login-clip-loader" color={"#7F56D9"} />
							<h1
								style={{
									color: "#7F56D9",
									fontSize: "2.4rem",
								}}
							>
								&nbsp;&nbsp;&nbsp;&nbsp;Fetching your document. Please wait...
							</h1>
						</div>
					) : (
						<>
							<div className="document_card_blk">
								{panelLoader ? (
									<div className="loaderWrapper">
										<DotLoader color="#7F56D9" className="login-clip-loader" />
										<h1
											style={{
												color: "#7F56D9",
												fontSize: "2.4rem",
											}}
										>
											&nbsp;&nbsp;&nbsp;&nbsp;Please wait...
										</h1>
									</div>
								) : userArticles?.length === 0 ? (
									<div
										className="col-lg-12 col-md-12 col-sm-12 col-12"
										style={{
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<ClimbingBoxLoader color="#7F56D9" />
										<h3 style={{ color: "#7F56D9", marginBottom: "0px" }}>
											Sorry No Data Available
										</h3>
									</div>
								) : (
									userArticles?.map((item, index) => {
										return (
											<div
												key={index}
												className="document_card"
												onClick={() => handleViewItem(item)}
												style={{
													cursor: "pointer",
													display: "flex",
													flexDirection: "column",
												}}
											>
												<h2>{item?.dataTitle}</h2>
												<span className="date">
													{moment(item?.createdAt).format("LL")}
												</span>
												<h5 className="mt-4">
													<Badge bg="primary" className="text-capitalize">
														{item?.articleType}
													</Badge>
												</h5>
											</div>
										);
									})
								)}
							</div>
						</>
					)}
				</div>
			</section>
		</>
	);
};

export default MyDocuments;
