import React from "react";
import Slider from "react-slick";

import profile from "../../../assets/images/profile.jpg";
import profile2 from "../../../assets/images/profile2.jpg";
import profile3 from "../../../assets/images/profile3.jpg";
import profile4 from "../../../assets/images/profile4.jpg";
import profile5 from "../../../assets/images/profile5.jpg";

const Reviews = () => {

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={`${className} left_arrow`}
            // className="left_arrow"
            style={{ ...style }}
            onClick={onClick}
          >
            <i class="fa-solid fa-arrow-right"></i>
          </div>
        );
      }
    
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={`${className} right_arrow`}
            style={{ ...style }}
            onClick={onClick}
          >
            <i class="fa-solid fa-arrow-left"></i>
          </div>
        );
      }

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 500,
    
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              // dots: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    return (
<section id="reviews">
        <h2 className="title">What our users say</h2>
        <p>Check out our amazing reviews</p>
        <Slider {...settings}>
          <div className="review_card">
            <blockquote className="review_text">
              “Who knew AI could write like Hemingway? Trolly crafts SEO
              articles that impress search engines AND readers. The unique blend
              of technology and creativity is a total game-changer. I'm not just
              SEO optimized, I'm Trolly-optimized”
            </blockquote>
            <div className="profile_blk">
              <div className="img_blk">
                <img src={profile} alt="" />
              </div>
              <div className="text_blk">
                <h6>Jack</h6>
                {/* <span>Manager @ Slytherin</span> */}
              </div>
            </div>
          </div>

          <div className="review_card">
            <blockquote className="review_text">
              “This AI marvel has changed the content game for my business. A
              future where keyword stuffing is a thing of the past is here.
              Trolly is your SEO wordsmith!”
            </blockquote>
            <div className="profile_blk">
              <div className="img_blk">
                <img src={profile2} alt="" />
              </div>
              <div className="text_blk">
                <h6>Oliver</h6>
                {/* <span>Manager @ Slytherin</span> */}
              </div>
            </div>
          </div>

          <div className="review_card">
            <blockquote className="review_text">
              “ Trolly's AI-generated articles are engaging, fresh, and
              optimized to rank. It's like having an SEO expert and a creative
              writer in one package. Phenomenal!”
            </blockquote>
            <div className="profile_blk">
              <div className="img_blk">
                <img src={profile3} alt="" />
              </div>
              <div className="text_blk">
                <h6>George</h6>
                {/* <span>Manager @ Slytherin</span> */}
              </div>
            </div>
          </div>

          <div className="review_card">
            <blockquote className="review_text">
              “Its AI generates articles that hook readers and please
              algorithms. It’s my secret weapon for SEO success.”
            </blockquote>
            <div className="profile_blk">
              <div className="img_blk">
                <img src={profile4} alt="" />
              </div>
              <div className="text_blk">
                <h6>Callum</h6>
                {/* <span>Manager @ Slytherin</span> */}
              </div>
            </div>
          </div>

          <div className="review_card">
            <blockquote className="review_text">
              “ Its AI-generated articles have sent my website soaring up the
              search rankings. The smartest content writer in the digital
              universe. Don't be left behind!”
            </blockquote>
            <div className="profile_blk">
              <div className="img_blk">
                <img src={profile5} alt="" />
              </div>
              <div className="text_blk">
                <h6>Liam</h6>
                {/* <span>Manager @ Slytherin</span> */}
              </div>
            </div>
          </div>
        </Slider>
      </section>
    )
}

export default Reviews;
