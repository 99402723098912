import React from "react";

import wordpress from "../../../assets/images/WordPress.svg";
import webflow from "../../../assets/images/WebFlow.svg";
import google from "../../../assets/images/Google.svg";
import medium from "../../../assets/images/Medium.svg";

const Brands = () => {
    return (
        <section id="brands">
            <div className="site_container">
                <p>write content for world’s most popular platforms</p>
                <ul className="brands_list">
                    <li>
                        <img src={wordpress} alt="" />
                    </li>
                    <li>
                        <img src={webflow} alt="" />
                    </li>
                    <li>
                        <img src={google} alt="" />
                    </li>
                    <li>
                        <img src={medium} alt="" />
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default Brands;
