import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../containers/Header/Header";
import {
  setAuthenticationToken,
  setUserDetails,
} from "../../redux/actions/auth";
import HeroSection from "./HeroSection/HeroSection";
import Brands from "./Brands/Brands";
import Productivity from "./Productivity/Productivity";
import SEOScores from "./SEOScores/SEOScores";
import Cheapest from "./Cheapest/Cheapest";
import ComparePlans from "./ComparePlans/ComparePlans";
import Reviews from "./Reviews/Reviews";
import PricinglandingPage from "./Pricing/Pricing";
import WhatsNext from "./WhatsNext/WhatsNext";

import "./landingPage.scss";
import PricingDetails from "./PricingDetails/PricingDetails";
import Footer from "../../containers/Footer/Footer";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../config/routes";

const LandingPage = () => {
  const dispatch = useDispatch();

  const aboutRef = useRef(null);

  const homeRef = useRef(null);

  const pricingRef = useRef(null);

  const handlePricingClick = () => {
    pricingRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleHomeClick = () => {
    homeRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleAboutClick = () => {
    aboutRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const user = useSelector((state) => state?.Authentication?.user);

  const token = useSelector((state) => state?.Authentication?.token);

  if (user && token && JSON.parse(user)?.isEmailVerified === false) {
    return <Redirect to={routes.auth.logIn} />;
  }

  if (user && token && JSON.parse(user)?.isEmailVerified === true) {
    return <Redirect to={routes.panel.dashboard} />;
  }

  return (
    <>
      <Header
        aboutClick={handleAboutClick}
        homeClick={handleHomeClick}
        pricingClick={handlePricingClick}
      />
      <HeroSection referHome={homeRef} referAbout={aboutRef} />
      <Brands />
      <Productivity />
      <SEOScores />
      <Cheapest />
      {/* <ComparePlans /> */}
      <Reviews />
      <div ref={pricingRef} />
      <PricinglandingPage />
      <PricingDetails />
      <WhatsNext />
      <Footer />
    </>
  );
};

export default LandingPage;
