import React from "react";

import output from "../../../assets/images/output.svg";
import p2 from "../../../assets/images/p2.svg";
import p3 from "../../../assets/images/p3.svg";
import p4 from "../../../assets/images/p4.svg";
import p5 from "../../../assets/images/p5.svg";
import p6 from "../../../assets/images/p6.svg";
import p7 from "../../../assets/images/p7.svg";
import p8 from "../../../assets/images/p8.svg";
import p9 from "../../../assets/images/p9.svg";
import p10 from "../../../assets/images/p10.svg";
import p11 from "../../../assets/images/p11.svg";
import p12 from "../../../assets/images/p12.svg";

const Productivity = () => {
  return (
    <section id="productivity">
      <div className="site_container">
        <h2 className="title">Timeless Productivity</h2>
        <p>
          The expectation that productivity should always lead to tangible
          results or accomplishments.
        </p>
        <button className="coming_soon_btn">v2.0 Features</button>
        <div className="row m-0">
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
            <div className="productivity_card">
              <div className="icon_blk">
                <img src={output} alt="" />
              </div>
              <h5>10+ Output Styles</h5>
              <p className="card_desc">
                Get content in different styles & variations.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
            <div className="productivity_card">
              <div className="icon_blk">
                <img src={p2} alt="" />
              </div>
              <h5>20+ Languages</h5>
              <p className="card_desc">
                Content can be produced in 20+ languages.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
            <div className="productivity_card">
              <p className="tag">Coming Soon</p>
              <div className="icon_blk">
                <img src={p3} alt="" />
              </div>
              <h5>SERP Benchmarks</h5>
              <p className="card_desc">
                Quick in-app benchmarks available to compare from.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
            <div className="productivity_card">
              <p className="tag">Coming Soon</p>

              <div className="icon_blk">
                <img src={p4} alt="" />
              </div>
              <h5>SEO Analytics</h5>
              <p className="card_desc">
                Get SEO tools to produce better content.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
            <div className="productivity_card">
              <div className="icon_blk">
                <img src={p5} alt="" />
              </div>
              <h5>In-Article Images & Videos</h5>
              <p className="card_desc">
                Add auto generated AI-Images to your content.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
            <div className="productivity_card">
              <div className="icon_blk">
                <img src={p6} alt="" />
              </div>
              <h5>Grammar Checker</h5>
              <p className="card_desc">
                In-app grammar correction to produce best possible outputs.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
            <div className="productivity_card">
              <div className="icon_blk">
                <img src={p7} alt="" />
              </div>
              <h5>Team Access</h5>
              <p className="card_desc">
                Allow team to access the features simultaneously.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
            <div className="productivity_card">
              <div className="icon_blk">
                <img src={p8} alt="" />
              </div>
              <h5>Bulk Article Generator</h5>
              <p className="card_desc">
                Generate 100s of articles in one click.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
            <div className="productivity_card">
              <div className="icon_blk">
                <img src={p9} alt="" />
              </div>
              <h5>API Access</h5>
              <p className="card_desc">
                Get access to Trolly APIs, connect & enjoy generating.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
            <div className="productivity_card">
              {/* <p className="tag">Coming Soon</p> */}

              <div className="icon_blk">
                <img src={p10} alt="" />
              </div>
              <h5>Zapier Automation</h5>
              <p className="card_desc">
                Automate content & connect with more than 3000 apps.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
            <div className="productivity_card">
              <p className="tag">Coming Soon</p>

              <div className="icon_blk">
                <img src={p11} alt="" />
              </div>
              <h5>WP Direct Automation</h5>
              <p className="card_desc">
                Publish content on wordpress directly.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-5">
            <div className="productivity_card">
              <div className="icon_blk">
                <img src={p12} alt="" />
              </div>
              <h5>Custom Styles</h5>
              <p className="card_desc">
                Built-in templates to get outputs as you like.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Productivity;
