export const languagesOption = [
    "English",
    "Spanish",
    "Chinese",
    "Portuguese",
    "Italian",
    "French",
    "German",
    "Indonesian",
    "Dutch",
    "Traditional Chinese",
    "Hebrew",
    "Hungarian",
    "Malaysian",
    "Arabic",
    "Urdu",
    "Hindi",
    "Polish",
    "Croatian",
    "Thai",
    "Vietnamese",
    "Brazilian"
];