import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./header.scss";
import routes from "../../config/routes";

const Header = ({ aboutClick, homeClick, pricingClick }) => {
  const [sidebar, setSidebar] = useState(false);

  const handleSideBar = () => {
    setSidebar((prev) => !prev);
  };

  return (
    <>
      <header id="header">
        <div className="menu_icon" onClick={handleSideBar}>
          <i className="fa-solid fa-bars"></i>
        </div>
        <div className="logo_blk">
          <img src={logo} alt="logo" />
        </div>
        <div className="nav_blk">
          <nav className={sidebar ? "active" : null}>
            <li onClick={homeClick}>
              <Link
                to={routes.home}
                className={`${
                  window.location.pathname === routes.home ? "active" : ""
                }`}
              >
                Home
              </Link>
            </li>
            <li
              onClick={aboutClick}
              style={{ color: "black", fontWeight: "600" }}
            >
              <Link to={routes.home}>Features</Link>
            </li>
            <li onClick={pricingClick}>
              <Link to={routes.home}>Pricing</Link>
            </li>
            <li>
              <Link
                to={routes.contact}
                className={`${
                  window.location.pathname === routes.contact ? "active" : ""
                }`}
              >
                Contact Us
              </Link>
            </li>
          </nav>

          <div className="btns_blk">
            <Link
              to={routes.auth.logIn}
              type="button"
              className="btn_without_border"
            >
              Log in
            </Link>
            <Link
              to={routes.auth.signUp}
              type="button"
              className="btn_with_loader"
            >
              <button type="button" className="btn_with_border">
                Try Free
              </button>
            </Link>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
