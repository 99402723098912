import React from "react";
import './privacyPolicy.scss';

const PrivacyPolicy = () => {
    return (
        <>
        </>
    )
}

export default PrivacyPolicy;
