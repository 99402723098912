import { auth } from "../type";

const initialState = {
  token: localStorage.getItem("Token") || "",
  user: localStorage.getItem("User") || "",
  authError: "",
  authLoader: false,
  redirectSuccess: false,
};

const Authentication = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case auth.SET_AUTH_TOKEN: {
      return {
        ...state,
        token: payload,
      };
    }
    case auth.SET_AUTH_ERROR: {
      return {
        ...state,
        authError: payload,
      };
    }
    case auth.SET_USER_DETAILS: {
      return {
        ...state,
        user: payload,
      };
    }
    case auth.SET_AUTH_LOADER: {
      return {
        ...state,
        authLoader: payload,
      };
    }
    case auth.SET_REDIRECT_SUCCESS: {
      return {
        ...state,
        redirectSuccess: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default Authentication;
