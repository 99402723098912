import { pricing } from "../type";

const initialState = {
    expiryDetail: {}
};

const Pricing = (state = initialState, action) => {
    const {type, payload} = action;

    switch(type) {
        case pricing.SET_EXPIRY_DETAILS: {
            return {
                ...state,
                expiryDetail: payload
            }
        }
        default: {
            return state;
        }
    }
}

export default Pricing;
