import React from "react";
import stats from "../../../assets/images/stats.png";

const Cheapest = () => {
    return (
        <section id="cheapest">
            <div className="site_container">
                <div className="inner">
                    <div className="img_blk">
                        <img src={stats} alt="" />
                    </div>
                    <div className="text_blk">
                        <h2>
                            We are the <span>cheapest</span>
                        </h2>
                        <p>
                            Highly in-demand features at budget friendly cost, in comparison
                            with other tools available in market.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Cheapest;
